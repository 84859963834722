export function override(baseClasses: string, newClasses?: string): string {
	if (typeof newClasses !== 'string') return baseClasses?.trim() ?? ''
	if (typeof baseClasses !== 'string') return newClasses?.trim() ?? ''

	// Split both strings into arrays, filter out empty values, and trim
	const baseClassArray = baseClasses
		.split(' ')
		.map((s) => s.trim())
		.filter(Boolean)
	const newClassArray = newClasses
		.split(' ')
		.map((s) => s.trim())
		.filter(Boolean)

	// Create a set to ensure uniqueness
	const uniqueClasses = new Set<string>([...baseClassArray, ...newClassArray])

	// Join the set back into a string
	return [...uniqueClasses].join(' ')
}
