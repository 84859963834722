<script lang="ts">
	import { override } from '$src/lib/utils/classnames'
	import { twMerge } from 'tailwind-merge'


	interface Props {
		embedded?: boolean;
		size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
		class?: string;
	}

	let { embedded = false, size = 'sm', class: className = '' }: Props = $props();
	

	let sizeClasses = $state('w-8 h-8')
	if (size === 'xs') {
		if (embedded) {
			sizeClasses = 'w-3 h-3'
		} else {
			sizeClasses = 'w-3 h-3'
		}
	} else if (size === 'sm') {
		if (embedded) {
			sizeClasses = 'w-4 h-4'
		} else {
			sizeClasses = 'w-4 h-4'
		}
	} else if (size === 'md') {
		if (embedded) {
			sizeClasses = 'w-5 h-5'
		} else {
			sizeClasses = 'w-32 h-32'
		}
	} else if (size === 'lg') {
		if (embedded) {
			sizeClasses = 'w-6 h-6'
		} else {
			sizeClasses = 'w-16 h-16'
		}
	} else if (size === 'xl') {
		if (embedded) {
			sizeClasses = 'w-6 h-6'
		} else {
			sizeClasses = 'w-16 h-16'
		}
	}

	let condensedClasses = $derived(twMerge(
		override(
			`animate-spin rounded-full border-b-2 border-brand-gray-2 dark:border-brand-gray-2 ${sizeClasses}`,
			className,
		),
	))
</script>

<div class={condensedClasses}></div>
